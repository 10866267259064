import './App.css';
import Navbar from "./components/general/Navbar"
import LandingPage from "./components/landing/LandingPage"
import NewsSection from "./components/landing/NewsSection"
import Contact from "./components/contact/Contact"
import Band from "./components/band/Band"
import Tour from "./components/tour/Tour"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Footer from './components/general/Footer';

function App() {
  return (
    <div className="App">
      {/* <Wrapper> */}
      <Router>
      <Navbar/>
        {/* <Route exact path="/" component={LandingPage}/> */}
        <Route exact path="/" >
          <LandingPage/>
          <NewsSection/>
          <Tour/>
          {/* <Band/> */}
          <Contact/>
          <Footer/>
        </Route>
        {/* <Route  path="/contact" component={Contact}/>
        <Route  path="/band" component={Band}/>
        <Route  path="/admin" component={Admin}/> */}
        {/* <Route  path="/" component={}/> */}
        {/* <Contact/>
              
              <LandingPage/>
              <NewsSection/> */}
        </Router>
      {/* </Wrapper> */}
     

     
    </div>
  );
}

export default App;
