import React, { useRef } from 'react';
// import './css/Contact.css';
// import { FiMail } from "react-icons/fi";
// import Notification from "./Notification";
// import { ReactComponent as Spinner } from "./assets/spinner.svg"
// import { ReactComponent as Check } from "../Icons/check.svg"
// import { ReactComponent as Error } from "../Icons/error.svg"
import emailjs from '@emailjs/browser'
import { render } from '@testing-library/react';

const styles = {
  container: {
    width: "100%",
    height: "75vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    alignItems: "center",
  },
  formContainer: {
    // marginTop: "10%",
    width: "50%"
  },
  inputField: {
    width: "100%",
    marginBottom: "1rem"
  },
  button: (mouseOnButton)=> {return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: mouseOnButton ? "white" :"black",
    height: "50px",
    minWidth: "125px",
    color: mouseOnButton ? "black" : "white",
    textTransform: "uppercase",
    cursor: "pointer",
    transition: "0.3s"
  }},
  buttonSuccess: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#1ecd97",
    height: "50px",
    minWidth: "125px"
  },
  buttonFailed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EE4443",
    height: "50px",
    minWidth: "125px"
  },
  spinner: {
    marginLeft: "10px"
  },
  flexContainer: {
    display: "flex",
    // flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    color: "white",
    fontSize: "2rem",
    marginBottom: "2rem"
  }
}

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      msgSent: null,
      msgFailed: null,
      emptyFields: false,
      mouseOnButton: false
    }

    this.templateId = process.env.REACT_APP_TEMPLATE_ID;
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })

  }

  handleSubmit(e) {
    e.preventDefault()
    const { name, subject, email, message } = this.state
    if (name && subject && email && message) {
      this.sendFeedback(this.templateId, { message_html: message, from_name: name, reply_to: email, email_subject: subject })
    } else {
      this.setState({ emptyFields: true })
      setTimeout(() => {
        this.setState({ emptyFields: false })
      }, 3000)
    }
  }

  sendFeedback(templateId, variables) {
    // this.setState({ loading: true })
    window.emailjs.send(
      process.env.REACT_APP_SERVICE_ID, templateId,
      variables
    )
      .then(() => {
        this.setState({ loading: false })
        this.setState({ name: "", subject: "", email: "", message: "" });
        this.setState({ msgSent: true })
        setTimeout(() => {
          this.setState({ msgSent: null })
        }, 5000)
      })
      .catch((error) => {
        console.log(error)
        this.setState({ loading: false })
        this.setState({ msgFailed: true })
        setTimeout(() => {
          this.setState({ msgFailed: null })
        }, 5000)
      })
  }

  // function sendEmail(e) {
  //   console.log(e.target)
  //   e.preventDefault();

  //   emailjs.sendForm('service_auuihn4', 'template_rfdga4v', e.target, '4GSDKvstpOqMv2PXz')
  //     .then((result) => {
  //         console.log(result);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // }

  renderButton() {
    if (this.state.msgSent) {
      return <button style={styles.buttonSuccess}>
        Sent
        {/* <Check style={styles.spinner} width="20" /> */}
      </button>
    } else if (this.state.msgFailed) {
      return <button style={styles.buttonFailed}>
        Error
      {/* <Error style={styles.spinner} width="20" /> */}
      </button>
    } else {
      return <button style={styles.button(this.state.mouseOnButton)} 
        onMouseEnter={()=> this.setState({mouseOnButton: true}) }
        onMouseLeave={()=> this.setState({mouseOnButton: false}) }
        >
        Submit
      {this.state.loading}
      </button>
    }
  }

  render() {

    return (
      <div style={styles.container} id="contact">
        <div style={styles.text}>Contact the band!</div>
        <div style={styles.formContainer}>
          <form autocomplete="off" onSubmit={this.handleSubmit}>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
              <input
                style={{...styles.inputField, ...{width: "49%"}}}
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleChange}
              />
              <input
                style={{...styles.inputField, ...{width: "49%"}}}
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <input
                style={styles.inputField}
                type="subject"
                name="subject"
                placeholder="Subject"
                value={this.state.subject}
                onChange={this.handleChange}
                />
            </div>
            
            <textarea
               style={styles.inputField}
              name="message"
              placeholder="Message"
              value={this.state.message}
              onChange={this.handleChange}
            ></textarea>
            <div style={styles.flexContainer}>
              <div>
                {this.renderButton()}
              </div>
              <div>
                {this.state.emptyFields && <p style={{ color: "red" }}>Please fill all empty fields!</p>}
              </div>
            </div>

          </form>
        </div>
      </div>
    )
  }
  // return (
  //   <>
  //     <form className="contact-form" onSubmit={sendEmail}>
  //     <input type="hidden" name="contact_number" />
  //     <label>Name</label>
  //     <input type="text" name="user_name" />
  //     <label>Email</label>
  //     <input type="email" name="user_email" />
  //     <label>Message</label>
  //     <textarea name="message" />
  //     <input type="submit" value="Send" />
  //   </form>
    
  //   </>
  // )

  // }
}
export default Contact
