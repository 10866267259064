import React from 'react';
import Background from '../../assets/images/BG.jpg';

const styles={
    section: {
        // backgroundImage: `url(${Background})`,
        
        width: "100%",
        height: "100vh",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    overlay: {
     
        width: "100%", /* Full width (cover the whole page) */
        height: "100vh", /* Full height (cover the whole page) */
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)", /* Black background with opacity */
        zIndex: 2, /* Specify a stack order in case you're using a different order for other elements */
    },
    cta: {
        border: "2px solid white",
        position: "absolute",
        left: "50%",
        top: "50%",
        marginLeft: "-150px",
        marginTop: "70px",
        padding: "20px",
        color: "white",
        fontSize: "1.5em",
        cursor: "pointer",
        transition: "0.4s"
    }
}

const LandingPage = () => {
    return (
        <div style={styles.section} id="home">
            <CallToAction/>
            <div style={styles.overlay}></div>
        </div>  
    )
}

const CallToAction = () => {
    return (
        <a href="https://www.youtube.com/watch?v=JN4bb7bAck0">
            <div style={styles.cta} className="cta">New song is out now!</div>
        </a>
    )
}

export default LandingPage
