import React from 'react'
import Sugar from 'sugar'
const styles={
    section: {
        backgroundColor: "rgba(0,0,0,0.5)",
        paddingTop: "4rem",
        width: "100%",
        height: "100vm",
        display: "flex",
        flexDirection: "column"
        // overflow: "scroll"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "10%",
        marginRight: "10%",
        borderTop: "1px solid lightgrey",
    },
    flexRow: {
        display: "flex",
        color: "white",
        paddingTop: "1em",
        paddingBottom: "1em",
        alignItems: "center"
    }
}
const Tour = () => {
    function randomDate(start, end) {
        let randomDateObj = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
        let SugarDate = Sugar.Date.create(randomDateObj)
        return Sugar.Date.format(SugarDate, '%D')

        
    }
    const [tourList, setTourList] = React.useState([
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Oulu", venue: "Jäähalli", country: "Finland" },
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Helsinki", venue: "Tavastia", country: "Finland" },
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Budapest", venue: "A38", country: "Hungary" },
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Oulu", venue: "Jäähalli", country: "Finland" },
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Oulu", venue: "Jäähalli", country: "Finland" },
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Oulu", venue: "Jäähalli", country: "Finland" },
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Oulu", venue: "Jäähalli", country: "Finland" },
        {date: randomDate(new Date(2021, 0, 1), new Date()), city: "Oulu", venue: "Jäähalli", country: "Finland" }
    ])
    return (
        <div style={styles.section} id="tour">
            <h1 style={{color: "white", paddingTop: "0.5rem", paddingBottom: "0.5rem", marginLeft: "10%"}}>Upcoming tourdates</h1>
            {/* {tourList && tourList.length > 0 && tourList.map(tour => {
                return <div style={styles.flexContainer}>
                    <div style={styles.flexRow}>
                        <p style={{marginRight: '1rem'}}>{tour.date}</p>
                        <p>{tour.venue}</p>
                    </div>
                    <div style={styles.flexRow}>
                        <p style={{marginRight: '0.5rem'}}>{tour.city},</p>
                        <p style={{marginRight: '1rem'}}>{tour.country}</p>
                        <button className="buyticket">TICKETS</button>
                    </div>
                </div> */}
            {/* })} */}
            <h1 style={{alignSelf: "center", color: "white", paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>Coming soon!</h1>
        </div>
    )
}

export default Tour
