import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as RouterLink
  } from "react-router-dom";
  import {ReactComponent as Logo} from "../../assets/images/logo.svg"
  import { useAuth0 } from '@auth0/auth0-react';
  import { Link } from 'react-scroll';

const styles = {
    leftSideLinks: {
        display: "flex",
        width: "40%",
        justifyContent: "flex-end"
    },
    rightSideLinks: {
        display: "flex",
        width: "40%",
        justifyContent: "flex-start"
    },
    navContainer: {
        zIndex: 3,
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        width: "100%",
        transition: "0.2s"
    },
    flexRow: {
        display: "flex",
        alignItems: "center"
    }
}

const Navbar = () => {
    const [scrolling, setScrolling] = React.useState(false)
    const { user } = useAuth0();

    React.useEffect(()=> {
        window.addEventListener("scroll", handleScroll)

        return ()=> {
            window.removeEventListener("scroll", handleScroll)
        }
    })

    function handleScroll() {
        if(window.scrollY < 400 ) {
            setScrolling(false)
        } else {
            setScrolling(true)
        }
    }

   

    return (
        <div id="navbar" className={scrolling && "nav"} style={!scrolling ? styles.navContainer : {...styles.navContainer, ...{position: "fixed", backgroundColor: "black"}}}>
            <div style={styles.leftSideLinks}>
                <ul style={styles.flexRow}>
                    {/* <li><Link activeClass="active" to="home" spy={true} smooth={true} duration={700} >Home</Link></li> */}
                    <li><Link activeClass="active" to="news" spy={true} smooth={true} duration={700} >News</Link></li>
                    <li><Link activeClass="active" to="tour" spy={true} smooth={true} duration={700} >Tour</Link></li>
                </ul>
            </div>
            <div className="logo" style={{cursor: "pointer", width: scrolling ? "5%" : "20%"}}>
                <Link activeClass="active" to="home" spy={true} smooth={true} duration={700} >
                    <Logo width={scrolling ? "40px" : "150px"} fill="white" style={{margin: scrolling ? "10px" : "40px"}}/>
                </Link>
            </div>
            <div style={styles.rightSideLinks}>
                <ul style={styles.flexRow}>
                    {/* <li><Link activeClass="active" to="band" spy={true} smooth={true} duration={700} >Band</Link></li> */}
                    {/* <li><Link activeClass="active" to="section1" spy={true} smooth={true} duration={700} >History</Link></li>     */}
                    {/* <li><Link activeClass="active" to="section1" spy={true} smooth={true} duration={700}>Support</Link></li> */}
                    <li><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700}>Contact</Link></li>
                    <li><a href="https://www.unomas.fi/en/category/1711695">Merch</a></li>
                </ul>
                <p style={{color: "white", position: "absolute", right: "10px"}}>{user && "Hi admin! Logout"}</p>
            </div>
            
        </div>
    )
}

export default Navbar
