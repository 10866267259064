import React from 'react';
import {ReactComponent as FacebookIcon} from "../../assets/icons/iconmonstr-facebook-1.svg";
import {ReactComponent as SoundCloudIcon} from "../../assets/icons/iconmonstr-soundcloud-1.svg";
import {ReactComponent as YoutubeIcon} from "../../assets/icons/iconmonstr-youtube-1.svg";
import {ReactComponent as SpotifyIcon} from "../../assets/icons/iconmonstr-spotify-5.svg";

const styles = {
    container: {
        width: "100%",
        height: "25vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,1)"
    },
    icon: {
        width: "1.5em",
        height: "1.5em"
    },
    flexRow: {
        display: "flex",
        alignItems: "center"
    }
}

const Footer = () => {
    return (
        <div style={styles.container}>
            <ul style={styles.flexRow}>
                <li className="footer-icon"><a href="https://open.spotify.com/artist/53lf6uwBqMrvwLUBSM4pTJ?si=xClD7UBHSwG5t-h8mLASIg&utm_source=copy-link"><SpotifyIcon fill="white" style={styles.icon}/></a></li>
                <li className="footer-icon"><a href="https://www.youtube.com/channel/UCmWgkieh6vabIh5FFw6vqPQ"><YoutubeIcon fill="white" style={styles.icon}/></a></li>
                <li className="footer-icon"><a href="https://www.facebook.com/NorthernEquation/"><FacebookIcon fill="white" style={styles.icon}/></a></li>
                <li className="footer-icon"><a href="https://soundcloud.com/northernequation"><SoundCloudIcon fill="white" style={styles.icon}/></a></li>
            </ul>
            
        </div>
    )
}

export default Footer
