import React from 'react'
const styles={
    section: {
        backgroundColor: "black",
        width: "100%",
        height: "100vh",
        paddingLeft: "10%",
        paddingRight: "10%",
    },
    videoContainer: {
        paddingTop: '2rem',
        paddingBottom: '2em',
        display: 'flex',
        justifyContent: "space-between"
    },
    tracklist: {

    }
}

function throttle(func, timeFrame) {
    let lastTime = 0;
    return () => {
        let now = new Date();
        if(now - lastTime >= timeFrame) {
            func()
            lastTime = now
        }
    }
}

const NewsSection = () => {
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const inputRef = React.useRef(0)
    const resize = () => {
        forceUpdate()
        // console.log(inputRef.current.offsetWidth)
    }
    React.useEffect(()=> {
        window.addEventListener('resize', throttle(resize, 150))
        return ()=> {
            window.removeEventListener('resize', resize)
        }
    },[])

    return (
        <div style={styles.section} id="news" ref={inputRef}>
            <div style={styles.videoContainer}>
                <div dangerouslySetInnerHTML={createMarkup1(inputRef.current.offsetWidth ? inputRef.current.offsetWidth : 1200)} />
                <div dangerouslySetInnerHTML={createMarkup2(inputRef.current.offsetWidth ? inputRef.current.offsetWidth : 1200)} />
                <div style={styles.tracklist} className="tracklist">
                    {/* <h2 style={{color: "white", marginLeft: "1rem"}}>Tracklist</h2>
                    <ol>
                        <li>Wings of dreams</li>
                        <li>Sick Masquerade</li>
                        <li>Independent</li>
                        <li>Man Machine</li>
                        <li>Eye of the Storm</li>
                        <li>Promises</li>
                        <li>Seeds of Unborn</li>
                    </ol> */}
                </div>
            </div>
        </div>
    )
}

function createMarkup1(refWidth) {
    return {
        __html: `
            <iframe 
                width="${refWidth / 2.4}"
                height="${refWidth / 3.6}"
                src="https://www.youtube.com/embed/SwtjJJNmGSg" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
    `}
}



function createMarkup2(refWidth) {
    return {
        __html: `
            <iframe 
                width="${refWidth / 2.4}"
                height="${refWidth / 3.6}"
                src="https://www.youtube.com/embed/JN4bb7bAck0" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; 
                autoplay; clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture" 
                allowfullscreen>
            </iframe>
    `}
}

export default NewsSection
